/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import React, { useCallback } from "react";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";

const pageContent =
  '<gatsby_donation amount="0" btntext="Proceed to Registration" donationid="16"' +
  'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
  'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="General Donation SMSF, USA offline" />';
const PageContent = React.memo(PageContentNonMemoized);

const GeneralDonationToSmsfUsaOfflineMobile = () => {
  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("smsf-usa-general-donation-offline", event),
    []
  );
  return (
    <DonationLayout
      seoData={{
        title: "General Donation to SMSF, USA offline [Mobile] - Donation",
      }}
    >
      <Grid
        columns={1}
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Box style={{ color: "#2d3550", textAlign: "center" }}>
          <div>
            <h1
              style={{
                color: "#2d3550",
                fontSize: "1.5em",
                fontWeight: "600",
                marginTop: "40px",
              }}
            >
              General Donation to SMSF USA offline
            </h1>
          </div>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent}
          />
        </Box>
      </Grid>
    </DonationLayout>
  );
};

export default GeneralDonationToSmsfUsaOfflineMobile;
